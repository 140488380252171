import * as React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';
//import { Link } from '@fluentui/react/lib/Link';
import { DetailsList, DetailsListLayoutMode, ConstrainMode, IColumn, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps } from '@fluentui/react/lib/DetailsList';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
//import { CommandBarButton, PrimaryButton, Button, IButtonStyles } from '@fluentui/react/lib/Button';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { TextField } from '@fluentui/react/lib/TextField';
//import { Text } from '@fluentui/react/lib/Text';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { IOverflowSetItemProps, OverflowSet } from '@fluentui/react/lib/OverflowSet';
import { CommandBarButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IContextualMenuProps, IContextualMenuListProps, IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { IconButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';

import { copyAndSort, extractError, datediffDays } from '../../functions'
import { Get, Send } from '../../http';
import { ErrorDialog } from '../dialogs/error.dialog';
import { InfoDialog } from '../dialogs/info.dialog';
import { ConfirmDialog } from '../dialogs/confirm.dialog';
import { Admin_LicenseKeyDialog } from '../dialogs/admin.licensekey.dialog';
import { Admin_ChangeLicenseDialog } from '../dialogs/admin.changelicense.dialog';
import { Admin_ChangeLicenseOwnerDialog } from '../dialogs/admin.changelicenseowner.dialog';
import { Admin_NewLicenseDialog } from '../dialogs/admin.newlicense.dialog';
import { Admin_UserInfoDialog } from '../dialogs/admin.userinfo.dialog';
import authService from '../api-authorization/AuthorizeService'


interface ILicensesProps {
    firstname: string;
    lastname: string;
}

interface ILicensesState {
    items: ILicense[];
    filteredItems: ILicense[];
    columns: IColumn[]  
    selection: Selection,
    loading: boolean;
    filterCompany?: string;
    filterHost?: string;
    filterValid?: string;
    filterSolution?: string;
    filterSolutions: IDropdownOption[];
    teachingVisible: boolean;
    teachingControlId: string;
}

interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    solutionUniqueName: string;
    host: string;
    validTo: Date;
    userCount: number;
    createdOn: Date;
    userId: string;
    userCompany: string;
    userFullName: string;
    userEmail: string;
    scope: string;
    tenantid: string;
}

const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
    <TooltipHost {...tooltipHostProps} />
);

//registerIcons({
//    icons: custom_icons
//});

// filter: https://codesandbox.io/s/rajesh-patil74-jzuiy?file=/src/DetailsList.CustomColumns.Example.tsx:2860-2866

export class Admin_UserLicenses extends React.Component<ILicensesProps, ILicensesState> {

    static displayName = Admin_UserLicenses.name;

    private _errorDialog = React.createRef<ErrorDialog>();
    private _infoDialog = React.createRef<InfoDialog>();
    private _confirmDialog = React.createRef<ConfirmDialog>();
    private _licenseKeyDialog = React.createRef<Admin_LicenseKeyDialog>();
    private _userInfoDialog = React.createRef<Admin_UserInfoDialog>();
    private _changeLicenseDialog = React.createRef<Admin_ChangeLicenseDialog>();
    private _changeOwnerDialog = React.createRef<Admin_ChangeLicenseOwnerDialog>();
    private _newLicenseDialog = React.createRef<Admin_NewLicenseDialog>();

    constructor(props: ILicensesProps, appState: ILicensesState) {
        super(props, appState);

        this.showUser = this.showUser.bind(this);
        this.changeLicense = this.changeLicense.bind(this);
        this.showLicenseKey = this.showLicenseKey.bind(this);
        this.onRenderItemColumn = this.onRenderItemColumn.bind(this);

        this.state = {
            items: [],
            filteredItems: [],
            filterValid: "",
            filterSolution: "",
            filterSolutions: [{ key: "", text: "All" }],
            loading: true,
            columns: this.getColumns(),
            selection: new Selection({
                onSelectionChanged: () => {
                    //this.setState({ canSave: this.state.selection.getSelectedCount() > 0 });
                },
                selectionMode: SelectionMode.single,
            }),
            teachingVisible: true,
            teachingControlId: "commandbar1"
        };

        this.loadSolutions();

        //initializeIcons();
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        return (

		<div className="card">

			<div className="card-header">
				<h4>Admin: All licenses</h4>
			</div>

			<div className="card-body">

                    <div>
                        <Label>Filters</Label>
                        <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}><tbody><tr>
                            {/*<td style={{ textAlign: "right", verticalAlign: "middle" }}><Text>Company:&nbsp;&nbsp;</Text></td>*/}
                            <td style={{ textAlign: "left", verticalAlign: "middle", width: "25%" }}>
                                <TextField id="filterCompany" styles={{ fieldGroup: { height: 30 } }} label="Company"
                                    value={this.state.filterCompany}
                                    onChange={(e, newValue) => {
                                        this.setState({ filterCompany: newValue });
                                    }}
                                    validateOnFocusOut={true}
                                    onGetErrorMessage={(value: string) => {
                                        return "";
                                    }}
                                />
                            </td>
                            {/*<td style={{ textAlign: "right", verticalAlign: "middle" }}><Text>Solution:&nbsp;&nbsp;</Text></td>*/}
                            <td style={{ textAlign: "left", verticalAlign: "middle", width: "25%" }}>
                                <Dropdown key="filterSolution" label="Solution"
                                    selectedKey={this.state.filterSolution}
                                    options={this.state.filterSolutions}
                                    onChange={(evt, option) => {
                                        if (option) {
                                            this.setState({ filterSolution: option.key as string });
                                        }
                                    }}
                                />
                            </td>
                            {/*<td style={{ textAlign: "right", verticalAlign: "middle" }}><Text>State:&nbsp;&nbsp;</Text></td>*/}
                            <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                                <Dropdown key="filterValid" label="State"
                                    selectedKey={this.state.filterValid}
                                    options={[{ key: "", text: "All", isSelected: true }, { key: "expired", text: "Expired" }, { key: "valid", text: "Valid" }]}
                                    onChange={(evt, option) => {
                                        if (option) {
                                            this.setState({ filterValid: option.key as string });
                                        }
                                    }}
                                />
                            </td>
                            {/*<td style={{ textAlign: "right", verticalAlign: "middle" }}><Text>Environment:&nbsp;&nbsp;</Text></td>*/}
                            <td style={{ textAlign: "left", verticalAlign: "middle", width: "25%" }}>
                                <TextField id="filterHost" styles={{ fieldGroup: { height: 30 } }} label="Environment"
                                    value={this.state.filterHost}
                                    onChange={(e, newValue) => {
                                        this.setState({ filterHost: newValue });
                                    }}
                                    validateOnFocusOut={true}
                                    onGetErrorMessage={(value: string) => {
                                        return "";
                                    }}
                                />
                            </td>
                            <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
                                <IconButton iconProps={{ iconName: 'Filter' }} onClick={(ev) => {
                                    this.filterItems();
                                }} />
                            </td>
                        </tr></tbody></table>

                        <br />
                    </div>

                    <CommandBar id="commandbar1"
                        items={[
                            {
                                key: 'newlicense',
                                text: 'New license',
                                ariaLabel: 'New license',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'CirclePlus' },
                                onClick: (evt) => {
                                    this.newLicense();
                                }
                            },
                            {
                                key: 'refresh',
                                text: 'Refresh',
                                ariaLabel: 'Refresh',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'Refresh' },
                                onClick: (evt) => {
                                    this.refresh();
                                }
                            }
                        ]}
                    />

                    {this.state.teachingVisible && (
                        <TeachingBubble
                            calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                            target={`#${this.state.teachingControlId}`}
                            isWide={true}
                            hasCloseButton={true}
                            closeButtonAriaLabel="Close"
                            onDismiss={() => {
                                this.setState({ teachingVisible: false });
                            }}
                            primaryButtonProps={{ children: "OK", onClick: () => { this.setState({ teachingVisible: false }); } }}
                            headline="License actions has been moved"
                        >
                        The other license actions (change/extend, etc.) has been moved to the last column in the list below (vertical dotted button)
                        </TeachingBubble>
                    )}

                    <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 420px)', width: '100%' }}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

                            <DetailsList
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                onRenderItemColumn={this.onRenderItemColumn}
                                compact={true}
                                columns={this.state.columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selection={this.state.selection}
                                constrainMode={ConstrainMode.unconstrained}
                                selectionPreservedOnEmptyClick={true}
                                items={this.state.filteredItems}
                            >
                            </DetailsList>

                        </ScrollablePane>
                    </div >		

                    {this.state.loading && (
                        <Stack {...{ horizontalAlign: 'start', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ paddingLeft: "10px" }}>
                            <Spinner size={SpinnerSize.large} />
                        </Stack>
                    )}

                    <ErrorDialog ref={this._errorDialog} ></ErrorDialog>
                    <InfoDialog ref={this._infoDialog} ></InfoDialog>
                    <ConfirmDialog ref={this._confirmDialog} ></ConfirmDialog>
                    <Admin_LicenseKeyDialog ref={this._licenseKeyDialog} ></Admin_LicenseKeyDialog>
                    <Admin_ChangeLicenseDialog ref={this._changeLicenseDialog}></Admin_ChangeLicenseDialog>
                    <Admin_ChangeLicenseOwnerDialog ref={this._changeOwnerDialog}></Admin_ChangeLicenseOwnerDialog>
                    <Admin_NewLicenseDialog ref={this._newLicenseDialog}></Admin_NewLicenseDialog>
                    <Admin_UserInfoDialog ref={this._userInfoDialog} ></Admin_UserInfoDialog>

			</div>
		</div>

            
        );
    }

    private onRenderDetailsHeader(props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        if (!props) {
            return null;
        }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    }

    private onRenderItemColumn(item: ILicense, index?: number, column?: IColumn) {
        if (column) {
            

            if (column.fieldName == "validTo" || column.fieldName == "createdOn") {
                return <div>{new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(new Date(item[column.fieldName]))}</div>;
                /*} else if (column.key == "download") {
                    if (new Date(item.validTo) > new Date()) {
                        return <Link onClick={(evt) => {
                            evt.preventDefault();
                            this.showDownloadDialog(item.solutionUniqueName, item.solutionDisplayName);
                        }}>Download</Link>
                    } else {
                        return <span></span>
                    }*/
            }  else if (column.fieldName == "action") {
                return <OverflowSet
                    aria-label="Actions"
                    role="menubar"
                    overflowItems={[{ key: 'fake' }]}
                    onRenderOverflowButton={(overflowItems: any[] | undefined) => {
                        const buttonStyles: Partial<IButtonStyles> = {
                            root: {
                                minWidth: 0,
                                padding: '0 4px',
                                alignSelf: 'stretch',
                                height: 'auto',
                            },
                        };
                        return (
                            <CommandBarButton
                                ariaLabel="Actions"
                                role="menuitem"
                                styles={buttonStyles}
                                menuIconProps={{ iconName: 'MoreVertical' }}
                                menuProps={{
                                    items: overflowItems!, shouldFocusOnMount: false,
                                    onRenderMenuList: (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
                                        let actionItems: IContextualMenuItem[] = [];
                                        let selected = this.state.selection.getSelection() as ILicense[];
                                        let selCount = selected.length;
                                        if (selCount == 1) {
                                            let diff = 0;
                                            let now = new Date();
                                            let vt = new Date(selected[0].validTo);
                                            if (vt > now) {
                                                diff = datediffDays(vt, now);
                                            } else {
                                                diff = 0;
                                            }
                                            if (diff < 28) { 
                                                actionItems.push({
                                                    key: 'extendlicense', name: "Extend license with 1 year", onClick: (evt) => {
                                                        evt.preventDefault();
                                                        this.extendLicense(selected[0] as ILicense, 12);
                                                    }
                                                });
                                            }
                                            actionItems.push({
                                                key: 'changelicense', name: "Change license", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.changeLicense(selected[0] as ILicense);
                                                }
                                            });
                                            actionItems.push({
                                                key: 'showlicensekey', name: "Show license key", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.showLicenseKey(selected[0] as ILicense);
                                                }
                                            });
                                            actionItems.push({
                                                key: 'delete', name: "Delete license", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.deleteLicense(selected[0] as ILicense);
                                                }
                                            });
                                            actionItems.push({
                                                key: 'showcontact', name: "Show contact of license", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.showUser(selected[0] as ILicense);
                                                }
                                            });
                                            actionItems.push({
                                                key: 'changeowner', name: "Change contact of license", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.changeOwner(selected[0] as ILicense);
                                                }
                                            });
                                        }

                                        menuListProps.items = actionItems;
                                        return defaultRender(menuListProps);
                                    }
                                }}
                            />
                        );
                    }}
                    onRenderItem={(item: IOverflowSetItemProps) => {
                        if (item.onRender) {
                            return item.onRender(item);
                        }
                        return (
                            <CommandBarButton
                                role="menuitem"
                                iconProps={{ iconName: item.icon }}
                                menuProps={item.subMenuProps}
                                text={item.name}
                            />
                        );
                    }}
                />
            } else {
                const fieldContent = item[column.fieldName as keyof ILicense];
                return <div>{fieldContent}</div>;
            }
        }
    }

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, filteredItems } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = copyAndSort(filteredItems, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            filteredItems: newItems
        });
    }

    private getColumns(): IColumn[] {
        return [
            ,
            {
                key: 'userCompany',
                name: 'Company',
                fieldName: 'userCompany',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'solutionName',
                name: 'Solution',
                fieldName: 'solutionDisplayName',
                minWidth: 150,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'host',
                name: 'Environment',
                fieldName: 'host',
                minWidth: 150,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'validTo',
                name: 'Valid to',
                fieldName: 'validTo',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'createdOn',
                name: 'Created on',
                fieldName: 'createdOn',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'action',
                name: '',
                fieldName: 'action',
                minWidth: 20,
                maxWidth: 40,
                isResizable: false
            }

        ];
    }



    filterItems(items?: ILicense[]) {
        if (!items)
            items = this.state.items;

        let filterComp = this.state.filterCompany;
        if (filterComp) {
            filterComp = filterComp.trim();
        }
        if (filterComp && filterComp != null && filterComp != "") {
            filterComp = filterComp.trim();
            items = items.filter(i => i.userCompany.toLowerCase().includes(filterComp.toLowerCase()));
        }
        let filterHost = this.state.filterHost;
        if (filterHost && filterHost != null && filterHost != "") {
            filterHost = filterHost.trim();
            items = items.filter(i => i.host.toLowerCase().includes(filterHost.toLowerCase()));
        }
        let filterValid = this.state.filterValid;
        let now = new Date();
        if (filterValid === "expired") {
            items = items.filter(i => new Date(i.validTo) < now);
        } else if (filterValid === "valid") {
            items = items.filter(i => new Date(i.validTo) > now);
        }
        let filterSolution = this.state.filterSolution;
        if (filterSolution !== "") {
            items = items.filter(i => i.solutionId.toLowerCase() == filterSolution.toLowerCase());
        }
        this.setState({ filteredItems: items });
    }

    refresh() {
        this.populateData();
    }

    async populateData() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/userlicenses", token).then((data: ILicense[]) => {
                _self.filterItems(data);
                _self.setState({ items: data, loading: false });
            });
        });

        //const response = await fetch('test1');
        //const data = await response.json();
        
    }

    loadSolutions() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/solutions", token).then((data: any[]) => {
                let options: IDropdownOption[] = data.map(s => {
                    return { key: s.solutionId as string, text: s.displayName };
                });
                options.unshift({ key: "", text: "All" });
                _self.setState({ filterSolutions: options });
            });
        });
    }

    showUser(license: ILicense) {
        this.setState({ loading: true });
        authService.getAccessToken().then((token) => {
            Get("api/admin/userinfo/"+license.userId, token).then((userInfo) => {
                this.setState({ loading: false });
                this._userInfoDialog.current.Show(userInfo, (args) => { });
            }).catch((err) => {                    
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error", extractError(err));
            });
        });
    }

    changeLicense(license: ILicense) {
        this._changeLicenseDialog.current.Show(license, (result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { UserLicenseId: license.userLicenseId, ValidTo: result.validTo, UserCount: result.userCount };
                Send("POST", "api/admin/changelicense", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("Change license", "License has been changed, check the licensekey", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Change license", extractError(err));
                });
            });

        });
    }

    extendLicense(license: ILicense, months: number) {
        this.setState({ loading: true });
        authService.getAccessToken().then((token) => {
            let data = { UserLicenseId: license.userLicenseId, Months: months };
            Send("POST", "api/admin/extendlicense", data, token).then((data) => {
                this.setState({ loading: false });
                this._infoDialog.current.Show("Extend license", "License has been extended, check the licensekey", true);
                this.populateData();
            }).catch((err) => {
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error extend license", extractError(err));
            });
        });
    }

    deleteLicense(license: ILicense) {
        this._confirmDialog.current.Show("Delete license", "Are you sure to delete the selected license?", false, (result) => {
            if (result.answer) { 
                this.setState({ loading: true });
                authService.getAccessToken().then((token) => {
                    let data = { UserLicenseId: license.userLicenseId };
                    Send("POST", "api/admin/deletelicense", data, token).then((data) => {
                        this.setState({ loading: false });
                        this._infoDialog.current.Show("Delete license", "License has been deleted", true);
                        this.populateData();
                    }).catch((err) => {
                        this.setState({ loading: false });
                        this._errorDialog.current.Show("Error delete license", extractError(err));
                    });
                });
            }
        });
    }

    changeOwner(license: ILicense) {
        this._changeOwnerDialog.current.Show(license, (result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { UserLicenseId: license.userLicenseId,  UserId: result.userId };
                Send("POST", "api/admin/changelicenseowner", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("Change license", "License owner has been changed", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Change owner", extractError(err));
                });
            });

        });
    }

    newLicense() {
        this._newLicenseDialog.current.Show((result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { UserId: result.userId, SolutionId: result.solutionId, Host: result.host, ValidTo: result.validTo, UserCount: result.userCount };
                Send("POST", "api/admin/newlicense", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("New license", "License has been created, check the licensekey", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error New license", extractError(err));
                });
            });

        });
    }

    showLicenseKey(license: ILicense) {
        this._licenseKeyDialog.current.Show({ solutionDisplayName: license.solutionDisplayName, userLicenseId: license.userLicenseId, host: license.host, userCount: license.userCount, validTo: license.validTo });
    }

}

