//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';

import { formatDate, formatNumber, isEmptyStr } from '../../functions';

export interface IUserInfoDialogOutput {

}

interface IDialogProps {

}

interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    host: string;
    users: number;
    validTo: Date;
    createdOn: Date;
}

interface IUser {
    fullName: string;
    companyName: string;
    jobTitle: string;
    email: string;
}

interface IUserInfo {
    licenses: ILicense[];
    user: IUser;
}

interface IDialogState {
    title: string;
    hide: boolean;
    userInfo: IUserInfo;
}

export class Admin_UserInfoDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IUserInfoDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

      this._onCancelClick = this._onCancelClick.bind(this);
      this._onOkClick = this._onOkClick.bind(this);

      this.state = {
          title: "Contact Info",
          hide: true,
          userInfo: {
              licenses: [],
              user: { fullName: "", companyName: "", jobTitle: "", email: "" }
          }
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >


                <table cellPadding="0" cellSpacing="0" style={{ border: '0px' }}>
                    <tbody>
                        <tr><td style={{ textAlign: "left" }}><Label>Company:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.userInfo.user.companyName}</td></tr>
                        <tr><td style={{ textAlign: "left" }}><Label>Contact:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.userInfo.user.fullName}</td></tr>
                        <tr><td style={{ textAlign: "left" }}><Label>Job title:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.userInfo.user.jobTitle}</td></tr>
                        <tr><td style={{ textAlign: "left" }}><Label>Email:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.userInfo.user.email}</td></tr>
                    </tbody>
                </table>

                {/*<table cellPadding="0" cellSpacing="0" style={{ border: '1px solid silver' }}>
                    <tbody>
                        <tr><th>Solution</th><th>Environment</th><th>Users</th><th>ValidTo</th></tr>
                        {(this.state.userInfo.licenses.map(l => {
                            return <tr key={l.userLicenseId}><td>{l.solutionDisplayName}</td><td>{l.host}</td><td>{formatNumber(l.users,0)}</td><td>{formatDate(l.validTo, "short")}</td></tr>
                        }))}
                    </tbody>
                </table>*/}

              <DialogFooter>
                <DefaultButton onClick={this._onCancelClick} text="Close" />
              </DialogFooter>
            </Dialog>      
        );
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ hide: true });            
        this._cbOk({  });
    }
   
    public Show(userInfo: IUserInfo, onOk: { (args: IUserInfoDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, userInfo: userInfo });


    }

}