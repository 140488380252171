import React, { Component, Fragment } from 'react';
import { NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
//import { withRouter } from 'react-router-dom'
//import { useHistory } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
//import { isMobile } from '../../functions';

import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { ContextualMenu, ContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { LinkContainer } from 'react-router-bootstrap'
//import { IContextualMenuProps, IContextualMenuListProps, IContextualMenuItem, ContextualMenuItemType, DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { IconButton } from '@fluentui/react/lib/Button';
//import { Link } from '@fluentui/react/lib/Link';
//import { useId } from '@uifabric/react-hooks';
//import { custom_icons } from '../../custom_icons';
import { Get } from '../../http';
//import { isArray } from 'util';


export class LoginMenu extends Component {

    //labelId = useId('callout-label');
    //descriptionId = useId('callout-description');

    //_personaElement = React.createRef();

    //routeChange = (path) => {
    //    let history = useHistory();
    //    history.push(path);
    //}

    constructor(props) {
        super(props);

        //this.routeChange = this.routeChange.bind(this);

        this.onSolutionsMenuOpened = this.onSolutionsMenuOpened.bind(this);
        //this.onSolutionClicked = this.onSolutionClicked.bind(this);

        this.onKnowledgebaseMenuOpened = this.onKnowledgebaseMenuOpened.bind(this);
        //this.onKnowledgebaseClicked = this.onKnowledgebaseClicked.bind(this);

        this.state = {
            isAuthenticated: false,
            isAdmin: false,
            userName: null,
            solutionMenuOpened: false,
            solutionMenuItems: [],
            knowledgebaseMenuOpened: false,
            knowledgebaseMenuItems: [],
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            isAdmin: user && this.isAdmin(user),
            userName: user && user.name,
            nickName: user && user.nickname
        });
    }

    isAdmin(user) {
        if (user) {
            if (Array.isArray(user.role)) {
                user.role.includes("Administrators");
            } else {
                return (user.role === "Administrators");
            }
        }
        return false;
    }

    render() {
        const { isAuthenticated, isAdmin, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, isAdmin, profilePath, logoutPath );
        }
    }

    closesolutionmenu() {
        this.setState({ solutionMenuOpened: false });
    }

    closeknowledgebasemenu() {
        this.setState({ knowledgebaseMenuOpened: false });
    }

    authenticatedView(userName, isAdmin, profilePath, logoutPath) {
        return (<Fragment>
            {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
            </NavItem>
            */}

            <NavItem>
                <TooltipHost
                    content="Solutions"
                    id="tooltip_solutions"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >

                    <IconButton
                        ariaLabel="Solutions"                       
                        iconProps={{ iconName: 'GiftBoxSolid' }}
                        styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                         menuProps={{
                            shouldFocusOnMount: true,
                            gapSpace: 2,
                            isBeakVisible: true,
                             onItemClick: (ev, item) => {
                                 document.body.click();
                              }, //{ return true },
                             onDismiss: (evt, dismissAll) => {
                                 if (dismissAll) { }
                             },
                            contextualMenuItemAs: (props) => {
                                return props.item.link ? <Link to={props.item.link} style={{ color: 'inherit', textDecoration: 'inherit' }} ><ContextualMenuItem {...props} /></Link> : <ContextualMenuItem {...props} />;
                            },
                            onMenuOpened: this.onSolutionsMenuOpened,
                            items: this.state.solutionMenuItems
                        }}
                        onRenderMenuIcon={(props) => {
                            return null;
                        }}
                        />

                </TooltipHost>
            </NavItem>

            {/*<NavItem>
                <TooltipHost
                    content="KnowledgeBase"
                    id="tooltip_knowledgebase"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >

                    <IconButton
                        ariaLabel="KnowledgeBase"
                        iconProps={{ iconName: 'KnowledgeArticle' }}
                        styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                        menuProps={{
                            shouldFocusOnMount: true,
                            gapSpace: 2,
                            isBeakVisible: true,
                            onItemClick: (ev, item) => {
                                document.body.click();
                            }, //{ return true },
                            onDismiss: (evt, dismissAll) => {
                                if (dismissAll) { }
                            },
                            contextualMenuItemAs: (props) => {
                                return props.item.link ? <Link to={props.item.link} style={{ color: 'inherit', textDecoration: 'inherit' }} ><ContextualMenuItem {...props} /></Link> : <ContextualMenuItem {...props} />;
                            },
                            onMenuOpened: this.onKnowledgebaseMenuOpened,
                            items: this.state.knowledgebaseMenuItems
                        }}
                        onRenderMenuIcon={(props) => {
                            return null;
                        }}
                    />

                </TooltipHost>
            </NavItem>*/}

            <NavItem>
                <TooltipHost
                    content="Downloads"
                    id="tooltip_downloads"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    <LinkContainer to={ "/download-explorer" }>
                        <IconButton
                            ariaLabel="Downloads" style={{ color: 'inherit', textDecoration: 'inherit' }}
                            iconProps={{ iconName: 'Download' }}
                            styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                        />
                    </LinkContainer>
                </TooltipHost>
            </NavItem>

            <NavItem>
                <TooltipHost
                    content="Knowledge Base"
                    id="tooltip_kb"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    <LinkContainer to="/" onClick={(evt) => {
                        window.open("https://kb.ctse.nl");
                    }}>
                        <IconButton
                            ariaLabel="Knowledge Base" style={{ color: 'inherit', textDecoration: 'inherit' }}
                            iconProps={{ iconName: 'UnknownSolid' }}
                            styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                        />
                    </LinkContainer>
                </TooltipHost>
            </NavItem>

            <NavItem>
                <TooltipHost
                    content="My licenses"
                    id="tooltip_licenses"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    <LinkContainer to={"/licenses"}>
                        <IconButton 
                            ariaLabel="My licenses" style={{ color: 'inherit', textDecoration: 'inherit' }}
                            iconProps={{ iconName: 'PermissionsSolid' }}
                            styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}                        
                        />
                    </LinkContainer>
                </TooltipHost>
            </NavItem>

            {isAdmin && (
                <NavItem>
                    <TooltipHost
                        content="Admin"
                        id="tooltip_admin"
                        calloutProps={{ gapSpace: 0 }}
                        styles={{ root: { display: 'inline-block' } }}
                    >
                        {/*<LinkContainer to={"/admin"}>
                        <IconButton
                            ariaLabel="Admin" style={{ color: 'inherit', textDecoration: 'inherit' }}
                            iconProps={{ iconName: 'AdminALogo32' }}
                            styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                        />
                    </LinkContainer>*/}

                        <IconButton
                            ariaLabel="Admin"
                            id="tooltip_admin"
                            iconProps={{ iconName: 'AdminALogo32' }}
                            styles={{ icon: { color: 'rgb(0, 120, 212)', fontSize: 24 } }}
                            menuProps={{
                                shouldFocusOnMount: true,
                                gapSpace: 2,
                                isBeakVisible: true,
                                onItemClick: (ev, item) => {
                                    document.body.click();
                                }, //{ return true },
                                onDismiss: (evt, dismissAll) => {
                                    if (dismissAll) { }
                                },
                                contextualMenuItemAs: (props) => {
                                    return <Link to={props.item.link} style={{ color: 'inherit', textDecoration: 'inherit' }} ><ContextualMenuItem {...props} /></Link>
                                },
                                items: [
                                    {
                                        key: "alllicenses",
                                        text: "All Licenses",
                                        link: "/admin/licenses"
                                    },
                                    {
                                        key: "allusers",
                                        text: "All Users",
                                        link: "/admin/users"
                                    }
                                ]
                            }}
                            onRenderMenuIcon={(props) => {
                                return null;
                            }}
                        />
                    </TooltipHost>
                </NavItem>
                )}

                <NavItem>

                    <TooltipHost
                        content="Account"
                        id="tooltip_account"
                        calloutProps={{ gapSpace: 0 }}
                        styles={{ root: { display: 'inline-block' } }}
                    >

                    <IconButton
                        ariaLabel="Account"
                        iconProps={{ iconName: 'info' }}
                        menuProps={{
                            shouldFocusOnMount: true,
                            gapSpace: 2,
                            isBeakVisible: true,
                            onItemClick: (ev, item) => {
                                //document.body.click();
                                return true;
                            }, //{ return true },
                            contextualMenuItemAs: (props) => {
                                    return props.item.link ? <Link to={props.item.link} style={{ color: 'inherit', textDecoration: 'inherit' }} ><ContextualMenuItem {...props} /></Link> : <ContextualMenuItem {...props} />;
                            },
                            items: [
                                { key: 'logout', text: 'Logout', ariaLabel: 'Logout', link: logoutPath },
                                //{ key: 'test', text: 'test', ariaLabel: 'test', link: '/' },
                                { key: 'account', text: 'Account details', ariaLabel: 'Account details', link: profilePath }
                            ]
                        }}
                        onRenderIcon={(props) => {
                            return <Persona size={PersonaSize.size32} className="persona-button" hidePersonaDetails={true} imageInitials={this.state.nickName} />
                        }}
                        onRenderMenuIcon={(props) => {
                            return null;
                        }}
                        />

                    </TooltipHost>

                </NavItem>            

        </Fragment>);

    }

    _getMenu(props) {
        // Customize contextual menu with menuAs
        return <ContextualMenu {...props} />;
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem> */}
            {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Loginzzz</NavLink>
            </NavItem> */}

            <NavItem>
                <TooltipHost
                    content="Login"
                    id="tooltip_login"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    <LinkContainer to={loginPath}>
                        <IconButton
                            ariaLabel="Login"
                            iconProps={{ iconName: 'Contact' }}
                            onRenderIcon={(props) => {
                                return <Persona size={PersonaSize.size32} className="persona-button" hidePersonaDetails={true} />
                            }}
                            onRenderMenuIcon={(props) => {
                                return null;
                            }}
                        />
                    </LinkContainer>
                </TooltipHost>
            </NavItem>
        </Fragment>);
    }

    onSolutionsMenuOpened = (ctx) => {
        if (ctx != undefined) {
            if (this.state.solutionMenuItems.length == 0) {
                // set loading indicator
                this.setState({
                    solutions: [
                        {
                            key: 'loading',
                            onRender: (item, dismissMenu) => (
                                <Stack {...{ horizontal: true, verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ height: "36px" }}>
                                    &nbsp;&nbsp;<Spinner size={SpinnerSize.xSmall} />
                                    <span>Loading solutions...</span>
                                </Stack>
                            )
                        }
                    ]
                });

                authService.getAccessToken().then((token) => {
                    Get("api/solutions", token).then((solutions) => {
                        let solutionMenuItems = solutions.map((m) => {
                            return {
                                key: m.uniqueName,
                                //data: m.solutionId,
                                text: m.displayName,
                                link: "/solutions/" + m.uniqueName
                                //onClick: this.onSolutionClicked
                            };
                        });
                        this.setState({ solutionMenuItems: solutionMenuItems });
                    });
                });
            }
        }

    }

    onKnowledgebaseMenuOpened = (ctx) => {
        if (ctx != undefined) {
            if (this.state.knowledgebaseMenuItems.length == 0) {
                // set loading indicator
                this.setState({
                    solutions: [
                        {
                            key: 'loading',
                            onRender: (item, dismissMenu) => (
                                <Stack {...{ horizontal: true, verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ height: "36px" }}>
                                    &nbsp;&nbsp;<Spinner size={SpinnerSize.xSmall} />
                                    <span>Loading solutions...</span>
                                </Stack>
                            )
                        }
                    ]
                });

                authService.getAccessToken().then((token) => {
                    Get("api/solutions", token).then((solutions) => {
                        let knowledgebaseMenuItems = solutions.map((m) => {
                            return {
                                key: m.uniqueName,
                                //data: m.solutionId,
                                text: m.displayName,
                                link: "/knowledgebase/" + m.uniqueName
                                //onClick: this.onSolutionClicked
                            };
                        });
                        this.setState({ knowledgebaseMenuItems: knowledgebaseMenuItems });
                    });
                });
            }
        }

    }


}

//export default withRouter(LoginMenu)
