//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { TagPicker, IBasePicker, ITag } from "@fluentui/react/lib/Pickers";
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react/lib/Label';

import { isEmptyStr, stringtoNumber, formatNumber, addMonths, addYears } from '../../functions';
import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'

interface IDialogOutput {
    userId: string;
    solutionId: string;
    host: string;
    validTo: Date;
    userCount: number;
}

interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    userId: IUserTag;
    solutionId: string;
    host: string;
    validTo: Date;
    userCount: number,
    solutions: IDropdownOption[]
    users: IUserTag[];
    maxDate: Date;
}

interface IUserTag extends ITag {
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    enabled: boolean;
}

export class Admin_NewLicenseDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IDialogOutput): void; };
    private _userPicker = React.createRef<IBasePicker<IUserTag>>();

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onOkClick = this._onOkClick.bind(this);
        this.validate = this.validate.bind(this);
        this.searchUser = this.searchUser.bind(this);

        let now = new Date();

        this.state = {
            title: "New license",
            hide: true,
            invalid: true,
            userId: null,
            solutionId: null,
            host: null,
            validTo: addMonths(now, 1),
            userCount: 5,
            solutions: [],
            users: [],
            maxDate: addMonths(now, 13)
        };

        this.loadSolutions();
        this.loadUsers();

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >

                <Label>Contact</Label>
                <TagPicker
                    selectedItems={(this.state.userId) ? [this.state.userId] : []}
                    itemLimit={1}
                    onResolveSuggestions={(filter?: string, selectedItems?: IUserTag[]) => {
                        return this.searchUser(filter, selectedItems);
                    }}
                    getTextFromItem={item => item.name}
                    onDismiss={(ev?: any, selectedItem?: IUserTag) => {
                        return false; // prevent adding tag on dismiss
                    }}
                    pickerSuggestionsProps={{
                        noResultsFoundText: "Contact not found",
                    }}
                    resolveDelay={300}
                    inputProps={{
                        required: true,
                        autoComplete: "false"
                    }}
                    onBlur={() => { // clears typed text 
                        if (this._userPicker.current) {
                            let ctrl: any = this._userPicker.current;
                            if (ctrl.input.current) {
                                ctrl.input.current._updateValue("");
                            }
                        }
                    }}
                    onEmptyInputFocus={(selectedItems?: IUserTag[] | undefined) => {
                        if (selectedItems && selectedItems.length > 0) {
                            return [];
                        }
                        return this.searchUser(undefined, selectedItems);
                    }}
                    onChange={(items: IUserTag[]) => {
                        if (items && items.length > 0) {
                            this.setState({ userId: items[0] }, () => { this.validate(); });
                        } else {
                            this.setState({ userId: null }, () => { this.validate(); });
                        }
                    }}
                />

                <br />

                <Dropdown key="solution" label="Solution"
                    selectedKey={this.state.solutionId}
                    options={this.state.solutions}
                    onChange={(evt, option) => {
                        if (option) {
                            this.setState({ solutionId: option.key as string }, () => { this.validate(); });
                        }
                    }}
                />

                <br />

                <TextField key="host" label="Environment url"
                    value={this.state.host}
                    onChange={(e, newValue) => {
                        this.setState({ host: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <SpinButton key="userCount" min={0} max={999} iconButtonProps={{ style: { display: 'none' } }} /*isRequired={true}*/
                    value={(this.state.userCount != null) ? formatNumber(this.state.userCount, 0) : ""} label="Users"
                    onValidate={(value: string, evt: React.SyntheticEvent<HTMLElement>) => {
                        let num = stringtoNumber(value, ",");
                        if (isNaN(num)) {
                            this.setState({ userCount: null }, () => { this.validate(); });
                            evt.preventDefault();
                            return;
                        }
                        this.setState({ userCount: num }, () => { this.validate(); });
                        return formatNumber(num, 0);
                    }}
                />

                <br />

                <DatePicker key="validTo" label="Valid to"
                    isRequired={true}
                    value={this.state.validTo}
                    allowTextInput={false}
                    showGoToToday={true}
                    maxDate={this.state.maxDate}
                    formatDate={(date: Date | string) => {
                        if (date != undefined && date != null)
                            if (date instanceof Date) {
                                return date.toLocaleDateString('nl-NL');
                            } else {
                                return new Date(date).toLocaleDateString('nl-NL');
                            }
                        else
                            return "";
                    }}
                     onSelectDate={(date: Date | null | undefined) => {
                        if (date != this.state.validTo) {
                            this.setState({ validTo: date }, () => { this.validate(); });
                        }
                    }}
                    textField={{ styles: { fieldGroup: { selectors: { ":before": { content: "" } } } } }}
                    parseDateFromString={(val: string) => {
                        if (val == undefined || val == null || val == "")
                            return null;
                        let spl = (val.indexOf("-")) ? "-" : (val.indexOf("/")) ? "/" : "";
                        let parts = val.split(spl); //d-m-y
                        if (parts.length == 3) {
                            const now = new Date();
                            let day = Number(parts[0]);
                            let month = Number(parts[1]) - 1;
                            let year = Number(parts[2]);
                            if (year < 100)
                                year += now.getFullYear() - (now.getFullYear() % 100);
                            return new Date(year, month, day);
                        } else {
                            return null;
                        }

                    }}
                />
                          
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    loadSolutions() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/solutions", token).then((data: any[]) => {
                let options: IDropdownOption[] = data.map(s =>
                {
                    return { key: s.solutionId, text: s.displayName };
                });
                 _self.setState({ solutions: options });
            });
        });
    }

    loadUsers() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/users", token).then((data: any[]) => {
                let users: IUserTag[] = data.map(s => {
                    return { key: s.userId, name: s.fullName + " ("+s.companyName+")", fullName: s.fullName, firstName: s.firstName, lastName: s.lastName, email: s.email, companyName: s.companyName, enabled: s.enabled && !s.deleted };
                });
                _self.setState({ users: users });
            });
        });
    }

    searchUser(filter?: string, selectedItems?: IUserTag[]): IUserTag[] {
        if (filter && filter != "") {
            return this.state.users.filter(s => s.firstName.toLowerCase().includes(filter) || s.lastName.toLowerCase().includes(filter) || s.email.toLowerCase().includes(filter) || s.companyName.toLowerCase().includes(filter));
        } else {
            return this.state.users;
        }
    }

    reset() {
        let now = new Date();
        this.setState({
            userId: null,
            solutionId: null,
            host: null,
            validTo: addMonths(now, 1),
            userCount: 5,
            maxDate: addMonths(now, 13)
        });
    }

    validate() {
        let now = new Date();
        let nextYear = addYears(now, 1);
        let valid: boolean = (
            this.state.validTo !== null &&
            this.state.validTo <= nextYear &&
            this.state.userCount !== null &&
            this.state.userCount > 0 &&
            this.state.userId != null &&
            !isEmptyStr(this.state.solutionId) &&
            !isEmptyStr(this.state.host) &&
            this.state.host.startsWith("https://") &&
            this.state.host.endsWith(".dynamics.com")
        );
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });            
        this._cbOk({ userId: this.state.userId.key as string, solutionId: this.state.solutionId, host: this.state.host, userCount: this.state.userCount, validTo: this.state.validTo });
    }
   
    public Show(onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, invalid: true }, () => { this.reset(); });


    }

}