//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';

import { isEmptyStr} from '../../functions';

interface INewUserDialogOutput {
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    companyName: string;
    password: string;
}

interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    companyName: string;
    password: string;
    passwordConfirm: string;
    //enabled: boolean;
}

export class Admin_NewUserDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: INewUserDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onOkClick = this._onOkClick.bind(this);
        this.validate = this.validate.bind(this);

        this.state = {
            title: "New user",
            hide: true,
            invalid: true,
            firstName: null,
            lastName: null,
            email: null,
            jobTitle: null,
            companyName: null,
            password: null,
            passwordConfirm: null
            //enabled: null
        };

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >

                <TextField key="company" label="Company"
                    value={this.state.companyName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ companyName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="firstName" label="First name"
                    value={this.state.firstName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ firstName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="lastName" label="Last name"
                    value={this.state.lastName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ lastName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="jobTitle" label="Job Title"
                    value={this.state.jobTitle} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ jobTitle: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="email" label="E-mail address"
                    value={this.state.email} maxLength={250}
                    onChange={(e, newValue) => {
                        this.setState({ email: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="password" label="Password"
                    value={this.state.password} maxLength={100} type="password" autoComplete="new-password"
                    canRevealPassword={true}
                    onChange={(e, newValue) => {
                        this.setState({ password: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />
                <TextField key="passwordConfirm" label="Confirm password" type="password" autoComplete="new-password"
                    value={this.state.passwordConfirm} maxLength={100}
                    canRevealPassword={true}
                    onChange={(e, newValue) => {
                        this.setState({ passwordConfirm: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />
                          
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    reset() {
        let now = new Date();
        this.setState({
            firstName: null,
            lastName: null,
            email: null,
            jobTitle: null,
            companyName: null,
            password: null,
            passwordConfirm: null
            //enabled: null
        });
    }

    validate() {
        let valid: boolean = (
            !isEmptyStr(this.state.companyName) &&
            !isEmptyStr(this.state.firstName) &&
            !isEmptyStr(this.state.lastName) &&
            !isEmptyStr(this.state.jobTitle) &&
            !isEmptyStr(this.state.email) &&
            !isEmptyStr(this.state.password) &&
            !isEmptyStr(this.state.passwordConfirm) &&
            (this.state.password == this.state.passwordConfirm)
        );
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });            
        this._cbOk({ companyName: this.state.companyName, firstName: this.state.firstName, lastName: this.state.lastName, jobTitle: this.state.jobTitle, email: this.state.email, password: this.state.password });
    }
   
    public Show(onOk: { (args: INewUserDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, invalid: true }, () => { this.reset(); });


    }

}