import * as React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from '@fluentui/react/lib/Link';
import { DetailsList, DetailsListLayoutMode, ConstrainMode, IColumn, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps } from '@fluentui/react/lib/DetailsList';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
//import { CommandBarButton, PrimaryButton, Button, IButtonStyles } from '@fluentui/react/lib/Button';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';
import { IOverflowSetItemProps, OverflowSet } from '@fluentui/react/lib/OverflowSet';
import { CommandBarButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IContextualMenuProps, IContextualMenuListProps, IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

import { copyAndSort, extractError, isEmptyStr } from '../../functions'
import { Get, Send } from '../../http';
import { ErrorDialog } from '../dialogs/error.dialog';
import { InfoDialog } from '../dialogs/info.dialog';
import { ConfirmDialog } from '../dialogs/confirm.dialog';
import { Admin_ChangeUserDialog } from '../dialogs/admin.changeuser.dialog';
import { Admin_NewUserDialog } from '../dialogs/admin.newuser.dialog';
import { Admin_UserInfoDialog } from '../dialogs/admin.userinfo.dialog';
import { Admin_ChangePasswordDialog } from '../dialogs/admin.changepassword.dialog';
import authService from '../api-authorization/AuthorizeService'

interface IUsersProps {

}

export interface IUser {
    userId: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    jobTitle: string;
    enabled: boolean;
    deleted: boolean;
    createdOn: Date;
}

interface IUsersState {
    items: IUser[];
    filteredItems: IUser[];
    columns: IColumn[]  
    selection: Selection,
    loading: boolean;
    filter?: string;
    teachingVisible: boolean;
    teachingControlId: string;
    currentUserId: string;
}

const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
    <TooltipHost {...tooltipHostProps} />
);

//registerIcons({
//    icons: custom_icons
//});

// filter: https://codesandbox.io/s/rajesh-patil74-jzuiy?file=/src/DetailsList.CustomColumns.Example.tsx:2860-2866

export class Admin_Users extends React.Component<IUsersProps, IUsersState> {

    static displayName = Admin_Users.name;

    private _errorDialog = React.createRef<ErrorDialog>();
    private _infoDialog = React.createRef<InfoDialog>();
    private _confirmDialog = React.createRef<ConfirmDialog>();
    private _userInfoDialog = React.createRef<Admin_UserInfoDialog>();
    private _changeUserDialog = React.createRef<Admin_ChangeUserDialog>();
    private _newUserDialog = React.createRef<Admin_NewUserDialog>();
    private _changePasswordDialog = React.createRef<Admin_ChangePasswordDialog>();

    constructor(props: IUsersProps, appState: IUsersState) {
        super(props, appState);

        this.onRenderItemColumn = this.onRenderItemColumn.bind(this);

        this.state = {
            items: [],
            filteredItems: [],
            filter: "",
            loading: true,
            columns: this.getColumns(),
            selection: new Selection({
                onSelectionChanged: () => {
                    //this.setState({ canSave: this.state.selection.getSelectedCount() > 0 });
                },
                selectionMode: SelectionMode.single,
            }),
            teachingVisible: true,
            teachingControlId: "commandbar1",
            currentUserId: ""
        };

        //initializeIcons();
    }

    componentDidMount() {
        let _self = this;
        authService.getUser().then((user) => {
            _self.setState({ currentUserId: user.sub });
        });
        this.populateData();
    }

    render() {
        return (

		<div className="card">

			<div className="card-header">
				<h4>Admin: All Users</h4>
			</div>

			<div className="card-body">

                    <div>
                         <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}><tbody><tr>
                            {/*<td style={{ textAlign: "right", verticalAlign: "middle" }}><Text>Company:&nbsp;&nbsp;</Text></td>*/}
                            <td style={{ textAlign: "left", verticalAlign: "middle", width: "25%" }}>
                                <TextField id="filter" styles={{ fieldGroup: { height: 30 } }} label="Filter"
                                    value={this.state.filter}
                                    onChange={(e, newValue) => {
                                        this.setState({ filter: newValue });
                                    }}
                                    validateOnFocusOut={true}
                                    onGetErrorMessage={(value: string) => {
                                        return "";
                                    }}
                                />
                            </td>
                             <td style={{ textAlign: "left", verticalAlign: "bottom" }}>
                                <IconButton iconProps={{ iconName: 'Filter' }} onClick={(ev) => {
                                    this.filterItems();
                                }} />
                            </td>
                        </tr></tbody></table>

                        <br />
                    </div>

                    <CommandBar id="commandbar1"
                        items={[
                            {
                                key: 'newuser',
                                text: 'Create new user/contact',
                                ariaLabel: 'Create new user/contact',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'CirclePlus' },
                                onClick: () => {
                                    this.newUser();
                                }
                            },
                            {
                                key: 'refresh',
                                text: 'Refresh',
                                ariaLabel: 'Refresh',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'Refresh' },
                                onClick: () => {
                                    this.refresh();
                                }
                            }
                        ]}
                    />

                    {this.state.teachingVisible && (
                        <TeachingBubble
                            calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                            target={`#${this.state.teachingControlId}`}
                            isWide={true}
                            hasCloseButton={true}
                            closeButtonAriaLabel="Close"
                            onDismiss={() => {
                                this.setState({ teachingVisible: false });
                            }}
                            primaryButtonProps={{ children: "OK", onClick: () => { this.setState({ teachingVisible: false }); } }}
                            headline="Actions has been moved"
                        >
                            The other actions has been moved to the last column in the list below (vertical dotted button)
                        </TeachingBubble>
                    )}

                    <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 350px)', width: '100%' }}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

                            <DetailsList
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                onRenderItemColumn={this.onRenderItemColumn}
                                compact={true}
                                columns={this.state.columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selection={this.state.selection}
                                constrainMode={ConstrainMode.unconstrained}
                                selectionPreservedOnEmptyClick={true}
                                items={this.state.filteredItems}
                            >
                            </DetailsList>

                        </ScrollablePane>
                    </div >		

                    {this.state.loading && (
                        <Stack {...{ horizontalAlign: 'start', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ paddingLeft: "10px" }}>
                            <Spinner size={SpinnerSize.large} />
                        </Stack>
                    )}

                    <ErrorDialog ref={this._errorDialog} ></ErrorDialog>
                    <InfoDialog ref={this._infoDialog} ></InfoDialog>
                    <ConfirmDialog ref={this._confirmDialog} ></ConfirmDialog>
                    <Admin_ChangeUserDialog ref={this._changeUserDialog}></Admin_ChangeUserDialog>
                    <Admin_NewUserDialog ref={this._newUserDialog}></Admin_NewUserDialog>
                    <Admin_UserInfoDialog ref={this._userInfoDialog} ></Admin_UserInfoDialog>
                    <Admin_ChangePasswordDialog ref={this._changePasswordDialog} ></Admin_ChangePasswordDialog>

			</div>
		</div>

            
        );
    }

    private onRenderDetailsHeader(props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        if (!props) {
            return null;
        }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    }

    private onRenderItemColumn(item: IUser, index?: number, column?: IColumn) {
        if (column) {
            

            if (column.fieldName == "createdOn" || column.fieldName == "updatedOn") {
                if (item.createdOn && item.createdOn != null) {
                    return <div>{new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit"
                    }).format(new Date(item.createdOn))}</div>;
                } else {
                    return <div></div>
                }
            } else if (column.fieldName == "action") {
                return <OverflowSet
                    aria-label="Actions"
                    role="menubar"
                    overflowItems={[{ key: 'fake' }]}
                    onRenderOverflowButton={(overflowItems: any[] | undefined) => {
                        const buttonStyles: Partial<IButtonStyles> = {
                            root: {
                                minWidth: 0,
                                padding: '0 4px',
                                alignSelf: 'stretch',
                                height: 'auto',
                            },
                        };
                        return (
                            <CommandBarButton
                                ariaLabel="Actions"
                                role="menuitem"
                                styles={buttonStyles}
                                menuIconProps={{ iconName: 'MoreVertical' }}
                                menuProps={{
                                    items: overflowItems!, shouldFocusOnMount: false,
                                    onRenderMenuList: (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
                                        let actionItems: IContextualMenuItem[] = [];
                                        let selected = this.state.selection.getSelection() as IUser[];
                                        let selCount = selected.length;
                                        if (selCount == 1) {
                                            actionItems.push({
                                                key: 'showuser', name: "Show user/contact info", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.showUser(selected[0]);
                                                }
                                            });
                                            if (selected[0].userId.toLowerCase() != this.state.currentUserId) { 
                                                actionItems.push({
                                                    key: 'delete', name: "Delete user", onClick: (evt) => {
                                                        evt.preventDefault();
                                                        this.deleteUser(selected[0]);
                                                    }
                                                });
                                            }
                                            actionItems.push({
                                                key: 'changeuser', name: "Change user/contact", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.changeUser(selected[0]);
                                                }
                                            });
                                            actionItems.push({
                                                key: 'changepassword', name: "Change password", onClick: (evt) => {
                                                    evt.preventDefault();
                                                    this.changePassword(selected[0]);
                                                }
                                            });
                                        }

                                        menuListProps.items = actionItems;
                                        return defaultRender(menuListProps);
                                    }
                                }}
                            />
                        );
                    }}
                    onRenderItem={(item: IOverflowSetItemProps) => {
                        if (item.onRender) {
                            return item.onRender(item);
                        }
                        return (
                            <CommandBarButton
                                role="menuitem"
                                iconProps={{ iconName: item.icon }}
                                menuProps={item.subMenuProps}
                                text={item.name}
                            />
                        );
                    }}
                />
            } else {
                const fieldContent = item[column.fieldName as keyof IUser];
                return <div>{fieldContent}</div>;
            }
        }
    }

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, filteredItems } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = copyAndSort(filteredItems, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            filteredItems: newItems
        });
    }

    private getColumns(): IColumn[] {
        return [
            ,
            {
                key: 'companyName',
                name: 'Company',
                fieldName: 'companyName',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'fullName',
                name: 'Contact',
                fieldName: 'fullName',
                minWidth: 150,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'jobTitle',
                name: 'Job title',
                fieldName: 'jobTitle',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'email',
                name: 'E-mail',
                fieldName: 'email',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'createdOn',
                name: 'Created on',
                fieldName: 'createdOn',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'action',
                name: '',
                fieldName: 'action',
                minWidth: 20,
                maxWidth: 40,
                isResizable: false
            }

        ];
    }

    filterItems(items?: IUser[]) {
        if (!items)
            items = this.state.items;

        let filter = this.state.filter;
        if (filter && filter != null && filter != "") {
            filter = filter.trim();
            items = items.filter(i => i.companyName.toLowerCase().includes(filter.toLowerCase()) || i.fullName.toLowerCase().includes(filter.toLowerCase()) || i.email.toLowerCase().includes(filter.toLowerCase()));
        }
        this.setState({ filteredItems: items });
    }

    refresh() {
        this.populateData();
    }

    async populateData() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/users", token).then((data: IUser[]) => {
                _self.filterItems(data);
                _self.setState({ items: data, loading: false });
            });
        });

        //const response = await fetch('test1');
        //const data = await response.json();
        
    }

    showUser(user: IUser) {
        this.setState({ loading: true });
        authService.getAccessToken().then((token) => {
            Get("api/admin/userinfo/"+user.userId, token).then((userInfo) => {
                this.setState({ loading: false });
                this._userInfoDialog.current.Show(userInfo, (args) => { });
            }).catch((err) => {                    
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error", extractError(err));
            });
        });
    }

    changeUser(user: IUser) {
        this._changeUserDialog.current.Show(user, (result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { UserId: user.userId, CompanyName: result.companyName, FirstName: result.firstName, LastName: result.lastName, JobTitle: result.jobTitle, Email: result.email };
                Send("POST", "api/admin/changeuser", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("Change user", "User has been changed", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Change user", extractError(err));
                });
            });

        });
    }

    deleteUser(user: IUser) {
        this._confirmDialog.current.Show("Delete user", "Are you sure to delete the selected user?", false, (result) => {
            if (result.answer) {
                this.setState({ loading: true });
                authService.getAccessToken().then((token) => {
                    let data = { UserId: user.userId };
                    Send("POST", "api/admin/deleteuser", data, token).then((data) => {
                        this.setState({ loading: false });
                        this._infoDialog.current.Show("Delete user", "User has been deleted", true);
                        this.populateData();
                    }).catch((err) => {
                        this.setState({ loading: false });
                        this._errorDialog.current.Show("Error delete user", extractError(err));
                    });
                });
            }
        });
    }

    changePassword(user: IUser) {
        this._changePasswordDialog.current.Show(user, (result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { UserId: user.userId, Password: result.password };
                Send("POST", "api/admin/changepassword", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("Change password", "Password has been changed", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Change password", extractError(err));
                });
            });

        });
    }

    newUser() {
        this._newUserDialog.current.Show((result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { CompanyName: result.companyName, FirstName: result.firstName, LastName: result.lastName, JobTitle: result.jobTitle, Email: result.email, Password: result.password };
                Send("POST", "api/admin/newuser", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("New user", "User has been created", true);
                    this.populateData();
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error New user", extractError(err));
                });
            });

        });
    }

}

