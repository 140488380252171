//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
//import { Toggle } from '@fluentui/react/lib/Toggle';

import { isEmptyStr } from '../../functions';
import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'

export interface IExtensionDialogOutput {
    Reason: string;
}

interface IDialogProps {

}

interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    host: string;
    validTo: Date;
}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    userLicenseId: string;
    solutionName: string;
    host: string;
    reason: string;
}

export class ExtensionDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IExtensionDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

      this._onCancelClick = this._onCancelClick.bind(this);
      this._onOkClick = this._onOkClick.bind(this);
      this.validate = this.validate.bind(this);

      this.state = {
          title: "Request license extension",
          hide: true,
          invalid: true,
          userLicenseId: null,
          solutionName: null,
          host: null,
          reason: null
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >
                <TextField label="Solution" readOnly value={this.state.solutionName} />
                <TextField label="Environment url" readOnly value={this.state.host} />

                <TextField
                    label="Reason (explain why you need a license extension)"
                    required
                    multiline 
                    rows={10}
                    minLength={5}
                    maxLength={2000}
                    value={ this.state.reason} 
                    onChange={(e, newValue) => {
                        this.setState({ reason: newValue }, () => { this.validate() });
                    }}
                />
                           
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    validate() {
        let valid: boolean = (!isEmptyStr(this.state.reason));
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });            
        this._cbOk({ Reason: this.state.reason });
    }
   
    public Show(license: ILicense, onOk: { (args: IExtensionDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, userLicenseId: license.userLicenseId, solutionName: license.solutionDisplayName, host: license.host, reason: null, invalid: true });


    }

}