//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

export interface IConfirmDialogOutput {
    answer: boolean;
}

interface IDialogProps {

}

interface IDialogState {
  title: string;
  hide: boolean;
  message: string;
  isHtml: boolean;
}

export class ConfirmDialog extends React.Component<IDialogProps, IDialogState> {

    private _callback: { (args: IConfirmDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onOkClick = this._onOkClick.bind(this);
        this._onCancelClick = this._onCancelClick.bind(this);

      this.state = {
          title: "",
          hide: true,
          message: "",
          isHtml: false
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title
              }}
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >
                {this.state.isHtml && (
                    <div dangerouslySetInnerHTML={{ __html: this.state.message }}></div>              
                )}

                {!this.state.isHtml && (
                    <div>{this.state.message}</div>              
                )}

              <DialogFooter>
                    <PrimaryButton onClick={this._onOkClick} text="Yes" />
                    <DefaultButton onClick={this._onCancelClick} text="No" />
              </DialogFooter>
            </Dialog>      
        );
    }
 
    private _onOkClick() {
        this.setState({ hide: true });
        this._callback({ answer: true });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
        this._callback({ answer: false });
    }
   
    public Show(title: string, message: string, isHtml: boolean, cb: { (args: IConfirmDialogOutput): void; }): void {
        this._callback = cb;
        this.setState( { title: title, message: message, hide: false, isHtml: (isHtml)?true:false } );
    }

}