//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField, ITextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'

interface IDialogProps {

}

interface IDialogState {
  title: string;
  hide: boolean;
    licensekey: string;
    loading: boolean;
}

interface ILicenseKeyInfo {
    userLicenseId: string;
    //solutionId: string;
    solutionDisplayName: string;
    //validTo: Date;
}

/*
interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    host: string;
    validTo: Date;
}

*/

export class LicenseKeyDialog extends React.Component<IDialogProps, IDialogState> {

    private _licenseKeyRef = React.createRef<ITextField>();

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onCopyClick = this._onCopyClick.bind(this);

      this.state = {
          title: "",
          hide: true,
          loading: true,
          licensekey: ""       
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title
                }}
                maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >

                {this.state.loading && (
                    <Stack {...{ horizontalAlign: 'start', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ paddingLeft: "10px" }}>
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                )}

                {!this.state.loading && (
                    <TextField multiline autoAdjustHeight value={this.state.licensekey} componentRef={this._licenseKeyRef} />
                )}
           
              <DialogFooter>
                    <PrimaryButton onClick={this._onCopyClick} text="Copy" />
                    <DefaultButton onClick={this._onCancelClick} text="Close" />
              </DialogFooter>
            </Dialog>      
        );
    }
 
    private _onCopyClick() {
        if (this._licenseKeyRef.current != null) {
            this._licenseKeyRef.current.select();
            document.execCommand('copy');
        }
      }

    private _onCancelClick() {
        this.setState({ hide: true });
    }

    public Show(licenseInfo: ILicenseKeyInfo): void {
        let title = "License key for " + licenseInfo.solutionDisplayName;
          this.setState({ hide: false, loading: true, title: title, licensekey: "" });

          authService.getAccessToken().then((token) => {
              Get("api/licenses/" + licenseInfo.userLicenseId + "/licensekey", token).then((data) => {
                  this.setState({ loading: false, licensekey: data.licenseKey });                  
              });
          });

      }

}